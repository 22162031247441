import React, { Component } from "react"
import { GatsbyImage } from "gatsby-plugin-image"

export class OurTeam extends Component {
    render() {
        return (
            <div className="container mx-auto mb-16">
                <div className="py-8 lg:py-16 px-5">
                    <p className="text-lndred text-sm text-left xl:px-24 2xl:px-[192px]">We Are</p>
                    <p className="font-bold text-2xl text-left xl:px-24 2xl:px-[190px] lg:text-3xl max-w-4xl">{this.props.title}</p>
                    <div className="lg:flex mx-auto max-w-5xl pt-7 space-y-7 lg:space-y-0 lg:space-x-5 2xl:space-x-24 xl:translate-x-[-10px] 2xl:translate-x-[-45px]">
                        <div className="max-w-lg md:max-w-xl">
                            <div className="" dangerouslySetInnerHTML={{
                                __html: this.props.body,
                            }} />
                        </div>
                    </div>
                </div>

                <div className="mx-auto flex justify-center px-5">
                    <GatsbyImage image={this.props.teamPhoto} loading="eager" placeholder="none" title={this.props.teamPhotoTitle} alt={this.props.teamPhotoDesc} />
                </div>

                <div className="grid md:grid-cols-2 lg:grid-cols-3 md:px-14 lg:gap-x-5 xl:px-40 2xl:px-72 pt-14 gap-y-5">
                    {this.props.soloPhotos.map((image, index) => (
                        <div key={index} className="rounded-lg mx-auto w-1/2 md:w-3/4 lg:w-full">
                            <GatsbyImage
                                image={image.gatsbyImageData}
                                className='rounded-t-lg'
                                loading="eager"
                                quality='100'
                                placeholder="none"
                                title={image.title}
                                alt={image.description}
                            />
                            <p className="text-center border rounded-b-lg -mt-0.5 py-2 text-lndred">{image.title}</p>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}