import React, { Component } from "react"
import { GatsbyImage } from "gatsby-plugin-image"


export class BannerAbout extends Component {
    render() {
        return (
            <div className="container mx-auto mb-5 md:mb-32 lg:mb-5">
                <div className="py-8 lg:py-16 px-5">
                    <p className="text-lndred text-sm text-left xl:px-24 2xl:px-[192px]">Running Quickly</p>
                    <p className="font-bold text-2xl text-left xl:px-24 2xl:px-[190px] lg:text-3xl max-w-4xl">{this.props.title}</p>
                    <div className="grid lg:flex mx-auto max-w-5xl pt-7 space-y-7 lg:space-y-0 lg:space-x-5 2xl:space-x-24 xl:translate-x-[-10px] 2xl:translate-x-[-45px]">
                        <div className="max-w-lg md:max-w-3xl">
                            <div className="" dangerouslySetInnerHTML={{
                                __html: this.props.text1,
                            }} />
                            <div className="pt-5" dangerouslySetInnerHTML={{
                                __html: this.props.text2,
                            }} />
                        </div>
                        <div className="max-w-lg md:max-w-3xl">
                            <div className="" dangerouslySetInnerHTML={{
                                __html: this.props.text3,
                            }} />
                            <div className="pt-5" dangerouslySetInnerHTML={{
                                __html: this.props.text4,
                            }} />
                        </div>
                    </div>
                </div>
                <div className="mx-auto px-5 lg:hidden">
                    <GatsbyImage image={this.props.runningQuicklyImage} className='rounded-lg mx-auto' loading="eager" placeholder="none" title='About L&D Solutions Inc.' alt='About L&D Solutions Inc.' />
                    <div className="relative md:absolute translate-x-[0px] md:translate-x-[15px] -mt-10 md:-mt-20 px-5">
                        <div class="bg-white shadow-lg rounded-lg py-5 px-0.5 md:px-24 md:py-14 mx-auto">
                            <div class="grid grid-cols-3 gap-2 md:gap-5 text-center">
                                <div>
                                    <p class="text-xl md:text-4xl text-lndred font-bold">{this.props.runningQuicklyStats1}</p>
                                    <p class="text-xs md:text-base font-bold">{this.props.runningQuicklyStatsTitle1}</p>
                                </div>
                                <div class="border-l border-r px-4">
                                    <p class="text-xl md:text-4xl text-lndred font-bold">{this.props.runningQuicklyStats2}</p>
                                    <p class="text-xs md:text-base font-bold">{this.props.runningQuicklyStatsTitle2}</p>
                                </div>
                                <div>
                                    <p class="text-xl md:text-4xl text-lndred font-bold">{this.props.runningQuicklyStats3}</p>
                                    <p class="text-xs md:text-base font-bold">{this.props.runningQuicklyStatsTitle3}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hidden lg:flex">
                    <div className="relative lg:translate-x-[30px] 2xl:translate-x-[100px]">
                        <GatsbyImage image={this.props.runningQuicklyImage} className='rounded-lg w-[550px] xl:w-[650px] mx-auto px-5 xl:translate-x-[110px]' loading="eager" placeholder="none" title='About L&D Solutions Inc.' alt='About L&D Solutions Inc.' />
                        <div className="absolute translate-x-[410px] translate-y-[-260px] xl:translate-x-[600px] xl:translate-y-[-300px] 2xl:translate-x-[650px] 2xl:translate-y-[-290px]">
                            <div class="bg-white shadow-lg rounded-lg p-12 xl:px-20 xl:py-16 mx-auto">
                                <div class="grid grid-cols-3 gap-5 text-center">
                                    <div>
                                        <p class="text-4xl text-lndred font-bold">{this.props.runningQuicklyStats1}</p>
                                        <p class="font-bold">{this.props.runningQuicklyStatsTitle1}</p>
                                    </div>
                                    <div class="border-l border-r px-4">
                                        <p class="text-4xl text-lndred font-bold">{this.props.runningQuicklyStats2}</p>
                                        <p class="font-bold">{this.props.runningQuicklyStatsTitle2}</p>
                                    </div>
                                    <div>
                                        <p class="text-4xl text-lndred font-bold">{this.props.runningQuicklyStats3}</p>
                                        <p class="font-bold">{this.props.runningQuicklyStatsTitle3}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}