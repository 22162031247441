import * as React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { BannerAbout } from "../components/banner-about"
import { OurTeam } from "../components/our-team"
import MainSection from "../components/main-section"
import { BannerBottom } from "../components/banner-bottom"
import Seo from "../components/seo"
import GoogleReviews from "../components/reviews"

const AboutPage = (props) => {
  let keywords = ["L&D Solutions Inc.", "LND Solutions Inc.", "Post Collision Specialists", "Post Collision Services"]
  const aboutData = props.data.allContentfulAboutPage.edges
  return (
    <div>
      <Layout>
        <Seo
          title="About L&D Solutions Inc - Post Collision Seat Belt Repair & Upholstery Services"
          description="L&D Solutions is a Post Collision Specialty Shop in which we offer services for seat belt repairs, seat belt coloring, battery cable repair, 
          steering column sensor repair, srs airbag module repair, convertible roll bar repair, upholstery, and much more!"
          location={props.location.pathname}
          keywords={keywords}
        />
        <MainSection
          title='About L&D Solutions'
          body={aboutData[0].node.aboutLd.childMarkdownRemark.html}
          buttonPage="/services" buttonText="View All Services"
          mainImage={aboutData[0].node.mainImage.gatsbyImageData}
          mainImageSm={aboutData[0].node.mainImageMobile.gatsbyImageData}
          btnTitle="View All Services"
          btnAlt="View All Services"
        />
        <BannerAbout
          title={aboutData[0].node.runningQuicklySectionTitle.runningQuicklySectionTitle}
          text1={aboutData[0].node.runningQuicklyText1.childMarkdownRemark.html}
          text2={aboutData[0].node.runningQuicklyText2.childMarkdownRemark.html}
          text3={aboutData[0].node.runningQuicklyText3.childMarkdownRemark.html}
          text4={aboutData[0].node.runningQuicklyText4.childMarkdownRemark.html}
          runningQuicklyImage={aboutData[0].node.runningQuicklyImage.gatsbyImageData}
          runningQuicklyStatsTitle1={aboutData[0].node.runningQuicklyStatsTitle1}
          runningQuicklyStats1={aboutData[0].node.runningQuicklyStats1}
          runningQuicklyStatsTitle2={aboutData[0].node.runningQuicklyStatsTitle2}
          runningQuicklyStats2={aboutData[0].node.runningQuicklyStats2}
          runningQuicklyStatsTitle3={aboutData[0].node.runningQuicklyStatsTitle3}
          runningQuicklyStats3={aboutData[0].node.runningQuicklyStats3}
        />
        <OurTeam
          title={aboutData[0].node.meetOurTeamTitle.meetOurTeamTitle}
          body={aboutData[0].node.meetOurTeamBody.childMarkdownRemark.html}
          teamPhoto={aboutData[0].node.meetOurTeamTeamPhoto.gatsbyImageData}
          soloPhotos={aboutData[0].node.meetOurTeamSoloTeamMembers}
          teamPhotoTitle={aboutData[0].node.meetOurTeamTeamPhoto.title}
          teamPhotoDesc={aboutData[0].node.meetOurTeamTeamPhoto.description}
          soloPhotosTitle={aboutData[0].node.meetOurTeamSoloTeamMembers.title}
          soloPhotosDesc={aboutData[0].node.meetOurTeamSoloTeamMembers.description}
        />
        <GoogleReviews />
        <BannerBottom bannerData={aboutData[0].node} />
      </Layout>
    </div>
  )
}
export default AboutPage
export const pageQuery = graphql`
query{
  allContentfulAboutPage {
    edges {
      node {
        mainImage {
          gatsbyImageData (
             placeholder: NONE
             quality: 100
             )
      }
      mainImageMobile {
        gatsbyImageData (
          placeholder: NONE
          quality: 100
          )
        }
        aboutLd {
          childMarkdownRemark {
            html
          }
        }
        runningQuicklySectionTitle {
          runningQuicklySectionTitle
          childMarkdownRemark {
            html
          }
        }
        runningQuicklyText1 {
          childMarkdownRemark {
            html
          }
        }
        runningQuicklyText2 {
          childMarkdownRemark {
            html
          }
        }
        runningQuicklyText3 {
          childMarkdownRemark {
            html
          }
        }
        runningQuicklyText4 {
          childMarkdownRemark {
            html
          }
        }
        runningQuicklyImage {
          gatsbyImageData
        }
        meetOurTeamTitle {
          meetOurTeamTitle
          childMarkdownRemark {
            html
          }
        }
        meetOurTeamBody {
          childMarkdownRemark {
            html
          }
        }
        meetOurTeamTeamPhoto {
          gatsbyImageData(placeholder: NONE, quality: 100)
          title
          description
        }
        meetOurTeamSoloTeamMembers {
          gatsbyImageData(placeholder: NONE, quality: 100)
          title
          description
        }
        runningQuicklyStatsTitle1
        runningQuicklyStats1
        runningQuicklyStatsTitle2
        runningQuicklyStats2
        runningQuicklyStatsTitle3
        runningQuicklyStats3
        bottomBannerTitle
          bottomBannerBody {
              childMarkdownRemark {
              html
          }
        }
        bannerImage {
          gatsbyImageData
        }
      }
    }
  }
}
`
